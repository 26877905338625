export enum EnvironmentName {
  LOCAL = 'local',
  DEV = 'dev',
  STAGE = 'stage',
  PROD = 'prod',
}

export interface Environment {
  apiUrl: string;
  name: EnvironmentName;
  baseUrl: string;
}
